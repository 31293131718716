import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AuthService} from "./auth.service";
import {SharedModule} from "../shared/shared.module";
import {AuthGuard} from "./auth.guard";



@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  providers: [AuthService, AuthGuard]
})
export class AuthenticationModule { }

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="!(isHandset$ | async)"
  >
    <mat-toolbar class="sidenav_toolbar">
      <img
        width="150"
        alt="Billpocket Logo"
        src="../../assets/billpocket_horizontal_white.svg"
        style="margin: 10px"
      />
    </mat-toolbar>
    <mat-divider></mat-divider>
    <mat-nav-list>
      <a mat-list-item style="color: aliceblue;" routerLink="/ubercamping" routerLinkActive="active-link"><mat-icon style="margin-right: 1rem;">directions_car</mat-icon>Destino Uber</a>
      <a mat-list-item style="color: aliceblue;" routerLink="/openkeyboard" routerLinkActive="active-link"><mat-icon style="margin-right: 1rem;" >person</mat-icon>Activación Teclado Abierto</a>

    </mat-nav-list>
    ​
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar>
      <!-- BARRA SUPERIOR -->
      <mat-toolbar-row fxLayout="row">
        <button mat-button (click)="drawer.toggle()">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <img
          width="50"
          alt="Billpocket Logo"
          src="../../assets/Humvee-512.png"
          style="margin: 10px"
        />
        <span>Humvee</span>

        <mat-card-content style="margin-left: auto">
          <button  mat-icon-button [matMenuTriggerFor]="menu" style="margin-top: 0.5rem;">
            <mat-icon>settings</mat-icon>
          </button>
          <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item (click)="logOut()"><mat-icon>exit_to_app</mat-icon>Cerrar Sesión</button>
          </mat-menu>
        </mat-card-content>
      </mat-toolbar-row>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {BpHttpService} from '../shared/bp-http/bp-http.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BpHttpService {
  baseUrl: string;
  env: any;

  constructor(http: HttpClient) {
    super(http);
    this.baseUrl = `${environment.iauthUrl}/api/v1/clients/web/admin`;
    this.env = environment;
  }

  public isAuthenticated(): boolean {
    const token = sessionStorage.getItem('_token');
    if (!token) {
      return false;
    }
    return true;
  }

  public verifyPassword(password?: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + sessionStorage.getItem('_token'),
        Accept: 'application/json',
        'Content-Type': 'application/json'
      })
    };
    const body = { password };
    if (!this.env.production) {
      httpOptions.headers = httpOptions.headers.append(
        'X-Bp-User',
        sessionStorage.getItem('_xBpUser')
      );
    }
    return this.http.post(this.baseUrl + '/verifypassword', body, httpOptions);
  }
}

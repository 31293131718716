import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  snapshot: RouterStateSnapshot;

  constructor(
    public auth: AuthService,
    public router: Router,
  ) {
    this.snapshot = router.routerState.snapshot;
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this.auth.isAuthenticated()) {
      window.location.href = `${environment.iauthUrl}/login?returnUrl=${
        location.href
      }&isLocal=${!environment.production}`;
      const responseUrl = window.location.href.split('#');
      const payload = responseUrl[1].split('=');
      const tokenString = payload[3];
      const xBpUser = payload[payload.length - 1];
      if (xBpUser.includes('Employee')) {
        sessionStorage.setItem('_xBpUser', xBpUser.replace('%3A', ':'));
      }
      const bearerToken = tokenString
        .replace('&access_token', '')
        .replace('&refresh_token', '');
      sessionStorage.setItem('_token', bearerToken);
      window.location.href = environment.appUrl;
      return false;
    }
    return true;
  }

}

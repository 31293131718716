// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  appUrl: 'https://test.humvee.billpocket.com',
  iauthUrl: 'https://test.iauth.billpocket.com',
  apiUrl: 'https://test.prima.billpocket.com/api',
  apiCampaignRequests: 'https://test.tomahawk.billpocket.com/api/v1/campaing/admin',
  apiTeclado: 'https://test.tomahawk.billpocket.com/api/v1',
  apiUser: 'https://test.enterprise.billpocket.com/',
  isLocal:false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
